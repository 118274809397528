
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTvEzPjioPTHXC3rNCJqCKMIQ7367acJ0",
  authDomain: "booklabonline-54080.firebaseapp.com",
  projectId: "booklabonline-54080",
  storageBucket: "booklabonline-54080.appspot.com",
  messagingSenderId: "376984278769",
  appId: "1:376984278769:web:ea003e79027e4937177b50",
  measurementId: "G-D5C2GLFTSS"
};

// Initialize Firebase
export const apps = initializeApp(firebaseConfig);
const auth = getAuth(apps)
const db = getFirestore(apps);
export {auth,db}
