


export const AccountErrors = (error) => {
  switch (error) {
    case "auth/wrong-password":
      return "Your email or password is wrong!"
    case "auth/too-many-requests":
      return "To many request. try again later..."
    case "auth/user-not-found":
      return "You should sign up first."
    case "auth/email-already-in-use":
      return "Your email is already registered. try another email..."
      case "auth/invalid-email":
      return "Your email is invalid..."
      case "auth/invalid-phone-number":
      return "Your mobile number is invalid..."
      case "INVALID_PHONE_NUMBER : Invalid format":
      return "Your mobile number is invalid..."
      case "auth/requires-recent-login":
      return "Credential Too Old Login Again..."
      case "auth/invalid-credential":
      return "Invalid Username Password"
    default:
      return "Try again later..."
  }
}