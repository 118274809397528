const API_URL = 'https://booklabonline.com/admin/api';
export async function registration(name, email, phone, password) {
    let item = { name, email, phone, password }
    //console.warn(item);		
    let result = await fetch(`${process.env.REACT_APP_BASE_URL}/registration`,
      {
        method: 'POST',
        body: JSON.stringify(item),
        headers:
        {
          "Content-Type": 'application/x-www-form-urlencoded',
          "Accept": 'application/json'
        }
      })
    result = await result.json();  
    return result;
    //console.log(result);    
    //localStorage.setItem("user-info", JSON.stringify(item));
    
  }
 
  export async function booking(name, email, phone, age,gender,startDate,booking_status,time,reference_id,register_id,type) {
    try { 
      //console.log(name, email, phone, age,gender,startDate,booking_status,time,reference_id,register_id);
      let res = await fetch(`${process.env.REACT_APP_BASE_URL}/booking`, {
        method: "POST",
        body: JSON.stringify({
          name:name,
          email:email,
          phone:phone,
          age:age,
          gender:gender,
          date:startDate,
          type:type,
          booking_status: 'booked',
          time: (time !=='') ? (time): '',
          reference_id:4,
          register_id:register_id,
          
        }),
      });
     
      let resJson1 = await res.json(); 
      return resJson1;
    } catch (err) {
      //console.log(err);
    }
  };
  export async function city_check(city) {
    try { 
      //console.log(name, email, phone, age,gender,startDate,booking_status,time,reference_id,register_id);
      let res = await fetch(`${process.env.REACT_APP_BASE_URL}/city_check`, {
        method: "POST",
        body: JSON.stringify({
          city:city
        }),
      });
     
      let resJson1 = await res.json(); 
      //console.log(resJson1.data);
      return resJson1.data;
    } catch (err) {
      //console.log(err);
    }
  };
  export async function booking1({name, email, phone, age,gender,startDate,booking_status,time,reference_id,register_id,type,parameter}) {
    try { 
      //console.log(reference_id);
      let res = await fetch(`${process.env.REACT_APP_BASE_URL}/booking`, {
        method: "POST",
        body: JSON.stringify({
          name:name,
          email:email,
          phone:phone,
          age:age,
          gender:gender,
          date:startDate,
          type:type,
          parameter:parameter,
          booking_status: 'booked',
          time: (time !=='') ? (time): '',
          reference_id:reference_id,
          register_id:register_id,
          
        }),
      });
     
      let resJson1 = await res.json(); 
      return resJson1;
    } catch (err) {
      //console.log(err);
    }
  };

  export const fetchDataWithRetry = async (url, options) => {
    try {
      const response = await fetch(url, options);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      return response.json();
    } catch (error) {
      if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
        throw new Error('Network connection lost');
      } else {
        throw error;
      }
    }
  };

  export const fetchAddresses = async () => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer_address`);
    return response.json();
  };
  
  export const addAddress = async (address) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer_address`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ address }),
    });
    return response.json();
  };
  
  export const updateAddress = async (id, address) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer_address/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ address }),
    });
    return response.json();
  };
  
  export const deleteAddress = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/customer_address/${id}`, {
      method: 'DELETE',
    });
    return response.json();
  };