import "./App.css";
import React,{useEffect, useState,Suspense } from 'react';
import {AuthProvider} from './components/Pages/AuthContext';
import {onAuthStateChanged} from 'firebase/auth'
import {auth} from './components/Pages/firebase';
import {createBrowserRouter,RouterProvider } from "react-router-dom";
import PrivateRoute from './PrivateRoute'
import { useDispatch } from 'react-redux';
import{fetcharea,cityname,productlisting1} from "./features/counter/apiSlice";
import 'font-awesome/css/font-awesome.min.css';
import loadingLogo from './1024.png';
import { GetToken, CourierServiceability , Tracking_OrderId } from 'shiprocket-api'
const Lab = React.lazy(() => import('./components/Pages/Lab'));
const Health = React.lazy(() => import('./components/Pages/Health'));
const Home = React.lazy(() => import('./components/Pages/Home'));
const About = React.lazy(() => import('./components/Pages/About'));
const Blog = React.lazy(() => import('./components/Pages/Blog'));
const Contact = React.lazy(() => import('./components/Pages/Contact'));
const Register = React.lazy(() => import('./components/Pages/Register'));
const Login = React.lazy(() => import('./components/Pages/Login'));
const Dashboard = React.lazy(() => import('./components/Pages/Dashboard'));
const Specialist = React.lazy(() => import('./components/Pages/Specialist'));
const HowItWorks = React.lazy(() => import('./components/Pages/HowItWorks'));
const HealthDetails = React.lazy(() => import('./components/Pages/HealthDetails'));
const DoctorDetails = React.lazy(() => import('./components/Pages/DoctorDetails'));
const BlogDetails = React.lazy(() => import('./components/Pages/BlogDetails'));
const FavoriteDoctor = React.lazy(() => import('./components/Pages/FavoriteDoctor'));
const Profile = React.lazy(() => import('./components/Pages/Profile'));
const Labs = React.lazy(() => import('./components/Pages/Labs'));
const ChangePassword = React.lazy(() => import('./components/Pages/ChangePassword'));
const Doctor = React.lazy(() => import('./components/Pages/Doctor'));
const Team = React.lazy(() => import('./components/Pages/Team'));
const Partner = React.lazy(() => import('./components/Pages/Partner'));
const Digital = React.lazy(() => import('./components/Pages/Digital'));
const Clinic = React.lazy(() => import('./components/Pages/Clinic'));
const LabInfo = React.lazy(() => import('./components/Pages/LabInfo'));
const HealthPackage = React.lazy(() => import('./components/Pages/HealthPackage'));
const AirAmbulance = React.lazy(() => import('./components/Pages/AirAmbulance'));
const Schedule = React.lazy(() => import('./components/Pages/Schedule'));
const Error = React.lazy(() => import('./components/Pages/Error'));
const ProductListing = React.lazy(() => import('./components/Pages/ProductListing'));
const ProductDetails = React.lazy(() => import('./components/Pages/ProductDetails'));
const ForgetPassword = React.lazy(() => import('./components/Pages/ForgetPassword'));
const ViewCart = React.lazy(() => import('./components/Pages/ViewCart'));
const VerifyEmail = React.lazy(() => import('./components/Pages/VerifyEmail'));
const LocationComp = React.lazy(() => import('./components/Pages/LocationComp'));
const NoInternetConnection = React.lazy(() => import('./components/Pages/NoInternetConnection'));
const Medicose = React.lazy(() => import('./components/Pages/Medicose'));
const CheckOut = React.lazy(() => import('./components/Pages/CheckOut'));
const CheckOut1 = React.lazy(() => import('./components/Pages/CheckOut1'));
const Insurance = React.lazy(() => import('./components/Insurance'));
const OrderDetails = React.lazy(() => import('./components/Pages/OrderDetails'));
const Healthians = React.lazy(() => import('./components/Pages/Healthians'));
const Paypal = React.lazy(() => import('./components/Pages/Paypal'));
const ThankYou = React.lazy(() => import('./components/Pages/ThankYou'));
const Hospital = React.lazy(() => import('./components/Pages/Hospital'));
const LabBooking = React.lazy(() => import('./components/Pages/LabBooking'));
function App() {     
const [currentUser, setCurrentUser] = useState(null)
//const token = JSON.parse(localStorage.getItem('user-info-login')); 
//let params = useParams();
const dispatch=useDispatch();
//console.log(params.id);
//const navigate = useNavigate();  
const Loader = () => (
  <div className="loader-overlay">
  <img src={loadingLogo} alt="Loading..." className="loading-logo" />
</div>
);

useEffect(() => {
  dispatch(fetcharea());
 // dispatch(currentlatitude());
  //dispatch(currentlongitude());
  navigator.geolocation.getCurrentPosition(function(position) {   
    var latlong={"latitude":position.coords.latitude,"longitude":position.coords.longitude};
    dispatch(cityname(latlong)); 
  })
}, []);



//console.log(currentUser);
    useEffect(() => {   
      onAuthStateChanged(auth, (user) => {
        setCurrentUser(user)
        //console.log(auth);
       })
    }, [])
   
const handleLogin = async() => {
  ///  Get Token
  await GetToken({
    email : 'sandip.midas@gmail.com',
    password: 'Sandeep@123',
  })
  //console.log(token);
  ///  Check Courier Serviceability
   await CourierServiceability({
  
  auth: {
          email : 'sandip.midas@gmail.com',
          password: 'Sandeep@123',
      },
  params: {
      pickup_postcode : 122015,
      delivery_postcode : 284204,
      weight: 2,
      cod : 1
  }
  })
  
  //// Get Tracking Data through Order ID
   await Tracking_OrderId({
      auth: {
          email : 'sandip.midas@gmail.com',
          password: 'Sandeep@123',
      },
      params: {
          order_id : '55097',
      }
  })
  } 
        const router = createBrowserRouter([
          {
            path: "/",
            element:<Home/>,
          },
          {
            path: "/hospital",
            element: <Hospital/>,
          },
          {
            path: "/lab",
            element: <Lab/>,
          },
          {
            path: "/health",
            element: <Health />,
          },
          {
            path: "/register",
            element: <Register/>,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/about",
            element: <About />,
          },
          {
            path: "/contact",
            element: <Contact />,
          },
          {
            path: "/dashboard",
            element: <PrivateRoute Component={Dashboard} />,
          },
          {
            path: "/specialist",
            element: <Specialist/>,
          },
          {
            path: "/how-it-works",
            element: <HowItWorks />,
          },
          {
            path: "/health_details",
            element: <HealthDetails/>,
          },
          {
            path: "/doctor_details",
            element: <DoctorDetails/>,
          },
          {
            path: "/blog",
            element: <Blog />,
          },
          {
            path: "/blog/:id",
            element:<BlogDetails />,
          },
          {
            path: "/specialist/:id",
            element:<DoctorDetails/>,
          },
          {
            path: "/favorite_doctor",
            element:<FavoriteDoctor />,
          },
          {
            path: "/change_password",
            element:<PrivateRoute Component={ChangePassword} />,
          },
          {
            path: "/labs",
            element:<Labs />,
          },
          {
            path: "/labbooking",
            element:<LabBooking />,
          },
          {
            path: "/doctor",
            element:<Doctor/>,
          },
          {
            path: "/team",
            element:<Team />,
          },
          {
            path: "/partner",
            element:<Partner />,
          },
          {
            path: "/digital",
            element:<Digital />,
          },
          {
            path: "/clinic",
            element:<Clinic/>,
          },
          {
            path: "/labinfo",
            element:<LabInfo />,
          },
          {
            path: "/healthpackage",
            element:<HealthPackage />,
          },
          {
            path: "/airambulance",
            element:<AirAmbulance/>,
          },
          {
            path: "/schedule",
            element:<PrivateRoute Component={Schedule} />,
          },
          {
            path: "/productlisting",
            element:<ProductListing />,
          },
          {
            path: "/productlisting",
            element:<ProductListing />,
          },
          {
            path: "/productdetails/:id",
            element:<ProductDetails/>,
          },
          {
            path: "/verifyemail",
            element:<VerifyEmail/>,
          },
          {
            path: "/forgetpassword",
            element:<ForgetPassword/>,
          },
          {
            path: "/location",
            element:<LocationComp/>,
          },
          {
            path: "/medicose",
            element:<Medicose/>,
          },
          {
            path: "/checkout",
            element: <PrivateRoute Component={CheckOut} />,
          },
          {
            path: "/checkout1",
            element:<CheckOut1/>,
          },
          {
            path: "/insurance",
            element:<Insurance/>,
          },
          {
            path: "/cart",
            element:<ViewCart/>,
          },
          {
            path: "/order",
            element:<PrivateRoute Component={OrderDetails} />,
          },
          {
            path: "*",
            element:<Error/>,
          },
          {
            path: "/profile",
            element:<PrivateRoute Component={Profile} />,
          },
          {
            path: "/healthians",
            element:<Healthians/>,
          },
          {
            path: "/paypal",
            element:<Paypal/>,
          },
          {
            path: "/thankyou",
            element:<ThankYou/>,
          },
          {
            path: "/counter",
            element:<ThankYou/>,
          },
        ],
        {
        basename:'/' 
        });
        
  return (
    <div className="App">
       <Suspense fallback={<Loader />}>
        <NoInternetConnection></NoInternetConnection>
       <AuthProvider value={{currentUser}}>   
       <RouterProvider router={router} />
            </AuthProvider> 
            </Suspense>          
            </div>
  );
}

export default App;
