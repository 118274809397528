import PropTypes from 'prop-types'
import { createContext, useState, useEffect } from 'react'
import {
  productlistt,productlisting1
} from "../features/counter/productSlice";
import {  useSelector,useDispatch } from "react-redux";
export const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [])
  const productlist111 = useSelector(productlistt);
  const status = useSelector((state) => state.product.status);
  const [productList, setProductList] = useState([]); 
  const [labcategory, setLabCategory] = useState([]);
  const dispatch=useDispatch();
  //console.log(productList);
  let total1 = cartItems.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.quantity;
  }, 0);
  
 
useEffect(() => {

  if (status === 'idle') {
    dispatch(productlisting1());
  }
  if(status === 'succeeded')
    {
      //console.log(productlist111);
      setProductList(productlist111);
    }
}, [status, dispatch]);
  const addToCart = (item,quantit) => {
    
    
    const isItemInCart = cartItems.find((cartItem) => cartItem.product_id === item);

    if (isItemInCart) {
      //setQuantity(quantity + quantit);
      setCartItems(
        cartItems.map((cartItem) =>
          
          cartItem.product_id === item
            ? { ...cartItem, product_id: item,quantity: Number(cartItem.quantity) + Number(quantit) }
            : cartItem
        )
        
      );
    } else {
      //setQuantity(1);
      setCartItems([...cartItems, { product_id: item, quantity: quantit }]);
    }
  };

  const removeFromCart = (item) => {
   const isItemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);
  
    if (isItemInCart.quantity === 1) {
      setCartItems(cartItems.filter((cartItem) => cartItem.product_id !== item.product_id));
    } else {
      //setQuantity(quantity - 1);
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.product_id === item.product_id
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : cartItem
        )
      );
    }
  };
  const removeFromCart1 = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);
   
     if (isItemInCart.quantity === 1) {
       setCartItems(cartItems.filter((cartItem) => cartItem.product_id !== item.product_id));
     } else {
       //setQuantity(quantity - 1);
       setCartItems(cartItems.filter((cartItem) => cartItem.product_id !== item.product_id));
     }
   };
  const clearCart = () => {
    setCartItems([]);
    //setQuantity(0);
  };
  
  const getCartTotal = () => {
    if(typeof productList != 'undefined' && productList.length > 0)
      {
    return (cartItems.reduce((total, item) => 
    {
       
      const product_detail = productList.find((productlist1) => productlist1.product_id === item.product_id);
      
      return total + product_detail.product_price * item.quantity;
      }, 0)
    )
  }
}

  useEffect(() => {
    const data = localStorage.getItem('cartItems');
    if (data) {
      setCartItems(JSON.parse(data));
    }
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
   
  }, []);

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]); // Include cartItems as a dependency here
  useEffect(() => {
    localStorage.setItem('labcategory', JSON.stringify(labcategory));
  }, [labcategory]);
  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        removeFromCart1,
        clearCart,
        getCartTotal,
        total1,
        labcategory,
        setLabCategory
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
