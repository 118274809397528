import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//const client = new Twilio(accountSid, authToken);
const initialState = {
  data1: [],
  data2: [],
  product: [],
  loading: false,
  city: [],
  error: null,
  validation: null,
  flag: "",
  profile: [],
  confirmationResult: null,
  verifying: false,
  verified: false,
  latitude: "",
  longitude: "",
  status: "idle",
   displayName: null,
    photoURL: null,
};

export const productlisting1 = createAsyncThunk("productlisting1", async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/productlist`);
  return response.json();
});
const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(productlisting1.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    })
    .addCase(productlisting1.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "succeeded";
      state.product = action.payload;
    })
    .addCase(productlisting1.rejected, (state, action) => {
      state.loading = false;
      state.status = "failed";
      state.error = action.error.message;
    })
      ;
  }
});
export const {
  register,
  googlelogin,
  facebooklogin,
  startVerification,
  verificationSuccess,
  verificationFailure,
} = productSlice.actions;
export const productlistt = (state) => state.product.product.data;
export const productlist_status = (state) => state.product.status;
export default productSlice.reducer;
