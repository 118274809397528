import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  signInWithPhoneNumber,
  updateProfile
} from "firebase/auth";
import { auth} from "../../components/Pages/firebase";
import { AccountErrors } from "../../validation/account";
import { toast } from "react-toastify";
import { registration, booking, city_check } from "../../helper/formdata";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
const provider1 = new GoogleAuthProvider();
const provider = new FacebookAuthProvider();
//const client = new Twilio(accountSid, authToken);
const initialState = {
  data1: [],
  data2: [],
  product: [],
  loading: false,
  city: [],
  error: null,
  validation: null,
  flag: "",
  profile: [],
  confirmationResult: null,
  verifying: false,
  verified: false,
  latitude: "",
  longitude: "",
  status: "idle",
   displayName: null,
    photoURL: null,
};
// export const sendTextMessage = async (to, message) => {
//   try {
//     await client.messages.create({
//       body: message,
//       to,
//       from: twilioPhoneNumber,
//     });
//   } catch (error) {
//     throw new Error('Failed to send text message');
//   }
// };
export const updateProfile1 = createAsyncThunk(
  "profile/updateProfile1",
  async (userData, { rejectWithValue }) => {
    console.log(userData);
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("No user is currently signed in");
      const upload=await updateProfile({
        displayName: userData.displayName,
        photoURL: userData.photoURL,
      });
console.log(upload);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const signIn = createAsyncThunk("login", async ({ email, password },{ rejectWithValue }) => {
  
  try {
    await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem("user-info-login", "");
    localStorage.setItem("user-info-login", JSON.stringify(auth));
    //const userData = await getUserData(user.user.uid);
    //Navigate('/dashboard')
    //Navigate('/dashboard');
    // toast.update(wait, {
    //   render: `Welcome back ${email}`,
    //   type: "success",
    //   redirect: "/dashboard",
    //   isLoading: false,
    //   autoClose: 3000,
    //   hideProgressBar: false,
    //   progress: undefined,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    // });
    //navigate("/dashboard");
    //window.location.href = '/dashboard'
  } catch (error) {
    return rejectWithValue(error.code);
    // toast.update(wait, {
    //   render: AccountErrors(error.code),
    //   type: "error",
    //   isLoading: false,
    //   autoClose: 3000,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    // });
  }
});
export const verifyOTP = createAsyncThunk(`verifyOTP`, async (otp) => {
  //const navigate = useNavigate();
  try {
    //console.log(otp);
    window.confirmationResult.confirm(otp).then((result) => {
      // User signed in successfully.
      const user = result.user;
      //console.log(user);
      // ...
      //console.log(user.auth.currentUser);
      localStorage.setItem("user-info-login", "");
      localStorage.setItem("user-info-login", JSON.stringify(user.auth));
      //navigate("/dashboard");
      //window.location.href = '/dashboard'
    });
  } catch (error) {
    //dispatch(verificationFailure(error.message));
  }
});
export const signWithPhoneNumber = createAsyncThunk(
  "auth/signInWithPhoneNumber",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {
            //console.log('recaptcha resolved..')
          },
        }
      );
      const appVerifier = recaptchaVerifier;
      await signInWithPhoneNumber(auth, phoneNumber, appVerifier).then(
        (confirmationResult) => {
          window.confirmationResult = confirmationResult;
        }
      );
    } catch (error) {
      const wait = toast.loading("Please wait...");
      toast.update(wait, {
        render: AccountErrors(error.code),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }
);
export const signUp = createAsyncThunk(
  `registration`,
  async ({
    name,
    email,
    phone,
    age,
    gender,
    startDate,
    booking_status,
    time,
    reference_id,
    password,
    type,
  }) => {
    ////console.log(name,email,password);
    const wait = toast.loading("Please wait...");
    //const { user } = getState();
    try {
      //const guestData = await getUserData(user.uid);
      const registeredUser = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      localStorage.setItem("user-info-login", "");
      localStorage.setItem("user-info-login", JSON.stringify(auth));
      await registration(name, email, phone, password);
      const booked = await booking(
        name,
        email,
        phone,
        age,
        gender,
        startDate,
        booking_status,
        time,
        reference_id,
        registeredUser.user.uid,
        type
      );
      toast.update(wait, {
        render: `Welcome Welcome ${booked.message}`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
      window.location.href = "/schedule";
    } catch (error) {
      //console.log(error.code);
      toast.update(wait, {
        render: AccountErrors(error.code),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }
);
export const signUp1 = createAsyncThunk(
  `registration1`,
  async ({
    name,
    email,
    phone,
    age,
    gender,
    startDate,
    booking_status,
    time,
    reference_id,
    password,
    type,
  }) => {
    ////console.log(name,email,password);
    const wait = toast.loading("Please wait...");
    //const { user } = getState();
    try {
      //const guestData = await getUserData(user.uid);
      await createUserWithEmailAndPassword(auth, email, password);
      localStorage.setItem("user-info-login", "");
      localStorage.setItem("user-info-login", JSON.stringify(auth));
      await registration(name, email, phone, password);
    } catch (error) {
      //console.log(error.code);
      toast.update(wait, {
        render: AccountErrors(error.code),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }
);
export const user_register = createAsyncThunk(
  `register`,
  async ({ name, email, phone, password },{ rejectWithValue }) => {
    try {
      //const guestData = await getUserData(user.uid);
      await createUserWithEmailAndPassword(auth, email, password);
      await registration(name, email, phone, password);
      localStorage.setItem("user-info-login", "");
      localStorage.setItem("user-info-login", JSON.stringify(auth));
    } catch (error) {
      return rejectWithValue(error.code);
    }
  }
);
const city_get = async (city, latitude, longitude) => {
  let city_json = await city_check(city);
  //console.log(city_json);
  return city_json + " " + latitude + " " + longitude;
};
export const cityname = createAsyncThunk(
  `city`,
  async ({ latitude, longitude }) => {
    try {
      //console.log(latitude, longitude);
      // Make a request to a Geocoding API (e.g. Google Maps Geocoding API)

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyC0JhCZR3ilmCXiacjW4ME1Nd72lW76D_g`;
      return await fetch(url)
        .then((response) => response.json())
        .then((data) => {
          //console.log(data);
          // Parse the city name from the API response
          const city = data.results[0].address_components.find((component) =>
            component.types.includes("locality")
          ).long_name;
          return city_get(city, latitude, longitude);

          //return city_json.data;
        });
    } catch (error) {}
  }
);
export const productlisting1 = createAsyncThunk("productlisting1", async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/productlist`);
  return response.json();
});
export const fetcharea = createAsyncThunk("fetcharea", async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/area`);
  return response.json();
});

export const fetchalab = createAsyncThunk("fetchalab", async () => {
  const response = await fetch(`${process.env.REACT_APP_BASE_URL}/home_lab`);
  return response.json();
});


const apiSlice = createSlice({
  name: "api",
  initialState,
  reducers: {
    googlelogin: async () => {
      const wait = toast.loading("Please wait...");
      await signInWithPopup(auth, provider1)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          GoogleAuthProvider.credentialFromResult(result);
          const user = result.user;
          localStorage.setItem("user-info-login", "");
          localStorage.setItem("user-info-login", JSON.stringify(user.auth));
          toast.update(wait, {
            render: `Welcome ${user.auth.currentUser.email}`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          // Handle Errors here.
          toast.update(wait, {
            render: AccountErrors(error.code),
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    },
    facebooklogin: async () => {
      const wait = toast.loading("Please wait...");
      await signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          FacebookAuthProvider.credentialFromResult(result);
          localStorage.setItem("user-info-login", "");
          localStorage.setItem("user-info-login", JSON.stringify(user.auth));
          toast.update(wait, {
            render: `Welcome ${user.displayName}`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
          });
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          toast.update(wait, {
            render: AccountErrors(error.code),
            type: "error",
            isLoading: false,
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(productlisting1.pending, (state) => {
      state.loading = true;
      state.status = "loading";
    })
    .addCase(productlisting1.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "succeeded";
      state.product = action.payload;
    })
    .addCase(productlisting1.rejected, (state, action) => {
      state.loading = false;
      state.status = "failed";
      state.error = action.error.message;
    })
      .addCase(fetcharea.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetcharea.fulfilled, (state, action) => {
        state.loading = false;
        state.data1 = action.payload;
      })
      .addCase(fetcharea.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchalab.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchalab.fulfilled, (state, action) => {
        state.loading = false;
        state.data2 = action.payload;
      })
      .addCase(fetchalab.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(cityname.pending, (state) => {
        state.loading = true;
      })
      .addCase(cityname.fulfilled, (state, action) => {
        state.loading = false;
        //console.log(action.payload);
        let city_split = action.payload.toString().split(" ");
        state.latitude = city_split[2];
        state.longitude = city_split[3];
        state.city = { label: city_split[0], value: city_split[1] };
      })
      .addCase(cityname.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(signWithPhoneNumber.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signWithPhoneNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.confirmationResult = action.payload;
        state.flag = true;
      })
      .addCase(signWithPhoneNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.confirmationResult = action.payload;
        state.flag = false;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
        .addCase(updateProfile1.pending, (state) => {
          state.status = "loading";
        })
        .addCase(updateProfile1.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.displayName = action.payload.displayName;
          state.photoURL = action.payload.photoURL;
        })
        .addCase(updateProfile1.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        })
      .addCase(signIn.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        //toast.success('Login successful!');
      })
      .addCase(signIn.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        //console.log(action.payload);
        //toast.error('Login failed!');
      })
      .addCase(user_register.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(user_register.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        //toast.success('Login successful!');
      })
      .addCase(user_register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        //toast.error('Login failed!');
      });
      ;
  }
});
export const {
  register,
  googlelogin,
  facebooklogin,
  startVerification,
  verificationSuccess,
  verificationFailure,
} = apiSlice.actions;
export const labblist = (state) => state.api.data2.data;
export const arealist = (state) => state.api.data1.data;
export const currentcity = (state) => state.api.city;
export const currentlatitude = (state) => state.api.latitude;
export const currentlongitude = (state) => state.api.longitude;
export const verifyflag = (state) => state.api.flag;
export const productlistt = (state) => state.api.product.data;
export const productlist_status = (state) => state.api.status;
export default apiSlice.reducer;
